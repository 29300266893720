<template>
  <el-form :model="contractFormData" ref="ruleForm"   v-loading="loading">
    <h1 class="sub-module-title" >{{title}}</h1>
    <div class="sub-module-block">
      <div class="div-frac-average-14">
        <el-form-item label="Term Contract Type"  :rules="rules.reqRule" prop="termContractType">
          <el-input disabled v-model="contractFormData.termContractType"/>
        </el-form-item>
         <el-form-item label="Mitigating Company"  :rules="rules.reqRule" prop="mitigatingCompany">
           <el-select
              filterable
              v-model="contractFormData.mitigatingCompany"
              value-key="counterpartySysOrganizationId"
              disabled
            >
              <el-option
                v-for="item in sortByAlphabetical(companyOptionList, 'counterpartyCompanyName')"
                :key="item.counterpartySysOrganizationId"
                :label="item.counterpartyCompanyName"
                :value="item.counterpartySysOrganizationId"
              />
          </el-select>
        </el-form-item>
        <el-form-item label="Contract Number"  :rules="rules.reqRule" prop="contractNo">
          <el-input v-model="contractFormData.contractNo" :disabled="isAllFieldsDisabled || !hasEditPermission"/>
        </el-form-item>
        <el-form-item label="Contract Date Range"  :rules="rules.reqRule" prop="dateRange">
          <el-date-picker
           :disabled="isAllFieldsDisabled || !hasEditPermission"
            v-model="contractFormData.dateRange"
            type="daterange"
            range-separator="-"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            @change="hanleChangeDate"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="termContractType!==TERM_CONTRACT_TYPE.TC" label="Payment Terms"  :rules="rules.reqRule" prop="paymentTerm">
          <el-select
              v-model="contractFormData.paymentTerm"
               :disabled="isAllFieldsDisabled || !hasEditPermission"
              @change="handlePaymentTermChange"
            >
              <el-option
                 v-for="item in PAYMENT_TERM_OPTIONS"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value"
              />
          </el-select>
        </el-form-item>
        <el-form-item v-if="termContractType!==TERM_CONTRACT_TYPE.TC && contractFormData.paymentTerm ==='SPECIFIC_DATE'" label="Payment Term Date" prop="paymentTermDate" :rules="rules.reqRule">
          <el-date-picker
            :disabled="isAllFieldsDisabled || !hasEditPermission"
            v-model="contractFormData.paymentTermDate"
            type="date"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="termContractType!==TERM_CONTRACT_TYPE.TC && contractFormData.paymentTerm !=='SPECIFIC_DATE'" label="Credit Days" prop="creditDay"  :rules="rules.reqRule">
          <el-input-number
            v-model="contractFormData.creditDay"
            :disabled="contractFormData.paymentTerm === 'CASH_IN_ADVANCE' || isAllFieldsDisabled || !hasEditPermission"
            :controls="false" :precision="0" :min="0"
          />
        </el-form-item>
        <el-form-item label="Terms and Condition" prop="termsAndConditions">
          <el-input v-model="contractFormData.termsAndConditions"   :disabled="isAllFieldsDisabled || !hasEditPermission" />
        </el-form-item>
        <el-form-item label="Port" prop="port">
          <el-input v-model="contractFormData.port"  :disabled="isAllFieldsDisabled || !hasEditPermission" />
        </el-form-item>
        <el-form-item :class="(attachmentsList && attachmentsList.length <1) && 'margin-top'" label="" prop="attachments"   v-if="['EX_WHARF', 'DELIVERED'].includes(termContractType)">
            <el-upload
              class="upload-attachment"
              multiple
              :action="fileUploadUrl"
              :file-list="attachmentsList"
              :on-success="handleAttachmentUploadSuccess"
              :on-remove="handleRemove"
              :on-preview="handlePreview"
              :disabled="isAllFieldsDisabled || !hasEditPermission"
            >
              <el-button round outline size="small">{{ $t('uploadFile') }}</el-button>
            </el-upload>
        </el-form-item>
      </div>
    </div>
     <file-preview :visible.sync="isOpenFilePreview" :file="previewFile"></file-preview>

  </el-form>
</template>
<script>
import { capitalizeFirstLetter, sortByAlphabetical, getTodayISOString } from '@/utils/index.js'
import baseMixin from '@/mixins/base-mixin'
import FilePreview from '@/components/FilePreview'
import { PAYMENT_TERM_OPTIONS, TERM_CONTRACT_TYPE } from '@/constants.js'
export default {
  name: 'ContractInfomation',
  mixins: [baseMixin],
  components: { FilePreview },
  props: {
    title: String,
    termContractType: String,
    currentCompany: Object,
    formData: Object,
    rules: Object,
    isViewOnly: Boolean,
    isEdit: Boolean,
    hasEditPermission: Boolean,
    isAllFieldsDisabled: Boolean,
    companyOptionList: Array
  },
  created () {
    this.fileUploadUrl = this.$apis.baseUrl + this.$apis.uploadFile
    if (this.formData && this.isEdit) {
      this.getInitFormData()
    } else {
      this.contractFormData.mitigatingCompany = this.currentCompany.id
      this.contractFormData.termContractType = this.capitalizeFirstLetter(this.termContractType, '_')
    }
  },
  mounted () {
    const dateRange = [this.contractFormData.contractStartDate, this.contractFormData.contractEndDate]
    this.$set(this.contractFormData, 'dateRange', dateRange)
  },
  data () {
    const initContractFormData = {
      termContractType: this.termContractType,
      contractNo: null,
      mitigatingCompany: null,
      contractStartDate: getTodayISOString(),
      contractEndDate: getTodayISOString(),
      termsAndConditions: null,
      port: null,
      paymentTerm: null,
      paymentTermDate: getTodayISOString(),
      creditDay: 0,
      attachments: []
    }
    return {
      PAYMENT_TERM_OPTIONS,
      TERM_CONTRACT_TYPE,
      contractFormData: this.$_.cloneDeep(initContractFormData),
      fileUploadUrl: '',
      loading: false,
      isOpenFilePreview: false,
      previewFile: null,
      attachmentsList: []
    }
  },
  methods: {
    capitalizeFirstLetter,
    sortByAlphabetical,
    getInitFormData () {
      const formKeys = Object.keys(this.contractFormData)
      this.contractFormData = { ...this.$_.pick(this.formData, formKeys) }
      this.contractFormData.mitigatingCompany = this.formData.createdBySysOrganization
      if (this.formData.sellerAttachments.length > 0 || this.formData.buyerAttachments.length > 0) {
        const attachFiles = this.formData.buyerAttachments.length > 0 ? this.formData.buyerAttachments : this.formData.sellerAttachments
        delete this.formData.buyerAttachments
        delete this.formData.sellerAttachments
        this.downloadMultipleFileMixin(attachFiles).then(res => {
          const keys = Object.keys(res)
          this.contractFormData.attachments = keys
          this.attachmentsList = []
          keys.forEach((key, index) => {
            const attachment = {
              id: key,
              url: res[key],
              name: attachFiles.filter(e => e.id === key)[0].name
            }
            this.attachmentsList.push(attachment)
          })
        })
      } else {
        this.contractFormData.attachments = []
        this.attachmentsList = []
      }
      this.contractFormData.termContractType = this.capitalizeFirstLetter(this.contractFormData.termContractType, '_')
    },
    getContractFormData () {
      let isValid
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          isValid = true
        } else {
          isValid = false
          return null
        }
      })
      if (isValid) {
        this.contractFormData.contractStartDate = this.contractFormData.dateRange[0]
        this.contractFormData.contractEndDate = this.contractFormData.dateRange[1]
        if (this.termContractType === this.TERM_CONTRACT_TYPE.TC) {
          delete this.contractFormData.paymentTerm
          delete this.contractFormData.creditDay
          delete this.contractFormData.paymentTermDate
        } else {
          if (this.contractFormData.paymentTerm !== 'SPECIFIC_DATE') {
            delete this.contractFormData.paymentTermDate
          }
        }
        return this.contractFormData
      }
    },
    handlePaymentTermChange (val) {
      if (val === 'CASH_IN_ADVANCE') {
        this.contractFormData.creditDay = 0
      }
    },
    /*
     * Attachment methods
     */
    handleAttachmentUploadSuccess (response, file) {
      if (response.code === 1000) {
        const { id } = response.data
        file.id = id
        this.attachmentsList.push(file)
        this.contractFormData.attachments.push(id)
      }
    },
    handleRemove (file, fileList) {
      this.attachmentsList = this.attachmentsList.filter(item => item.id !== file.id)
      this.deleteFileMixin(file.id, false)
    },
    handlePreview (file) {
      this.previewFile = file
      this.isOpenFilePreview = true
    },
    hanleChangeDate (val) {
      // this.contractFormData.contractStartDate = val[0].to
      // this.contractFormData.contractEndDate = val[1]
      const obj = {
        contractStartDate: val[0]?.toISOString(),
        contractEndDate: val[1]?.toISOString()
      }
      this.$emit('update:formData', { ...this.formData, ...obj })
    }
  }
}
</script>
<style scoped lang="scss">
  @import "../index.scss";
  /* for date-picker range*/
  .el-range-editor{
    display: inline-flex !important;
  }
</style>
